import React, { Component } from 'react';

import { Image } from 'react-bootstrap';
import './payroll.scss';
import Breadbg from '../../assets/images/qsa/breadbg.jpg';

/* eslint-disable-next-line */


class Payroll extends Component {
  render(){
    return (
      <div>
        <div className="inner-banner text-center">
          <div className="bannerImage">
            <Image src={Breadbg}></Image>
          </div>
            <div className="container">
                <ul className="page-breadcrumb">
                  <li>
                    <a href="./index.html">Home</a>
                  </li>
                  <li>PMS</li>
                </ul>		
                <h1> Payroll Managment System</h1>
            </div>
        </div>
        <section className="kc-elm kc-css-36515 kc_row" >
          <div className="kc-row-container">
              <div className="kc-wrap-columns">
                <div className="kc-elm kc-css-843552 kc_col-sm-12 kc_column kc_col-sm-12">
                    <div className="kc-col-container">
                      <section className="single-service-proj service-style-one sec-pad solutions-page">
                          <div className="container">
                              <div className="sec-title text-center mb-3">
                                  <span></span>
                                  <h2>Payroll Managment System</h2>
                              </div>
                              <p>
                                  #1 cloud base Human Resource & Payroll Management Software. PMS makes it easy to onboard, pay, insure and support your hardworking team. PMS is one of the complete HR solutions industries and SME must implements for their growth.
                              </p>
                            
                              <div className="sec-title mb-2">
                                  <h4>Benefits</h4>
                              </div>
                              <div className="content mt-3">
                                  <ul>
                                      <li><i className="fa fa-check-circle"></i>Flexibility and power to handle any type of salary structure</li>
                                      <li><i className="fa fa-check-circle"></i>100% accurate and professional looking pay slip</li>
                                      <li><i className="fa fa-check-circle"></i>Automatically handle all taxes etc.</li>
                                      <li><i className="fa fa-check-circle"></i>Handling attendance of employees</li>
                                      <li><i className="fa fa-check-circle"></i>Handling deduction of all types of advances such as loan and final deduction</li>
                                      <li><i className="fa fa-check-circle"></i>Employee leaves info and salary deduction</li>
                                      <li><i className="fa fa-check-circle"></i>Employee pay scale and increment information</li>
                                      <li><i className="fa fa-check-circle"></i>Data protection</li>
                                      <li><i className="fa fa-check-circle"></i>Integrated with biometric</li>
                                      <li><i className="fa fa-check-circle"></i>SMS and E-mail intimation of attendance salary</li>
                                      <li><i className="fa fa-check-circle"></i>MIS reports</li>
                                      <li><i className="fa fa-check-circle"></i>Bank transfer</li>
                                      <li><i className="fa fa-check-circle"></i>Cash payments</li>
                                      <li><i className="fa fa-check-circle"></i>Performance management</li>
                                  </ul>
                              </div><br/><br/>
                              <div className="content mt-3">
                                  <h5>5 Reasons to Use PRMS</h5>
                                  <ul>
                                      <li><i className="fa fa-check-circle"></i>It is time -saving</li>
                                      <li><i className="fa fa-check-circle"></i>It maintains payroll information</li>
                                      <li><i className="fa fa-check-circle"></i>It is cost-effective</li>
                                      <li><i className="fa fa-check-circle"></i>No Human Involvement</li>
                                      <li><i className="fa fa-check-circle"></i>It optimizes the process</li>
                                  </ul>
                              </div><br/><br/>
                              <div className="content mt-3">
                                  <h5>Key Features of a PRMS</h5>
                                  <ul>
                                      <li><i className="fa fa-check-circle"></i>Automatic Payrolls</li>
                                      <li><i className="fa fa-check-circle"></i>Employee detail</li>
                                      <li><i className="fa fa-check-circle"></i>Daily attendance logs</li>
                                      <li><i className="fa fa-check-circle"></i>Leave Exemptions Management</li>
                                      <li><i className="fa fa-check-circle"></i>Monthly attendance summary</li>
                                      <li><i className="fa fa-check-circle"></i>Detail for deductions</li>
                                      <li><i className="fa fa-check-circle"></i>Holiday management</li>
                                      <li><i className="fa fa-check-circle"></i>Paid leaves management</li>
                                      <li><i className="fa fa-check-circle"></i>Half day management</li>
                                      <li><i className="fa fa-check-circle"></i>Overtime management</li>
                                      <li><i className="fa fa-check-circle"></i>Sandwich rule ( if Reuired )</li>
                                      <li><i className="fa fa-check-circle"></i>Monthly Salary Report</li>
                                      <li><i className="fa fa-check-circle"></i>Salary Comparison with previous month with difference salary</li>
                                      <li><i className="fa fa-check-circle"></i>SMS notification on Employee Salary</li>
                                      <li><i className="fa fa-check-circle"></i>SMS notification on Employee absent</li>
                                  </ul>
                              </div>
                          </div>
                      </section>
                    </div>
                </div>
              </div>
          </div>
        </section>
        
      </div>      
    );
  }
}

export default Payroll;
